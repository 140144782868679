<template>
  <div id="jsonContainer">
    <v-ace-editor
        v-model:value="localConfigString"
        lang="json"
        theme="chrome"
        :min-lines="30"
        :max-lines="500"
    />
  </div>
</template>

<script>
import {VAceEditor} from "vue3-ace-editor";

export default {
  name: "JSONConfig",
  data() {
    return {
      localConfigString: JSON.stringify(this.configJson, null, 2)
    }
  },
  watch: {
    localConfigString() {
        this.$emit("configUpdated", JSON.parse(this.localConfigString));
    }
  },
  components: { VAceEditor },
  props: [ "configJson" ]
}
</script>

<style scoped>
  #jsonContainer {
    height: 100%;
    width: 100%;
  }
</style>