<template>
  <div>
    <div class="row">
      <div class="col-4 configItem">
        <label>Filter Type</label>
        <n-select :options="[
            { label: 'Exclude Literal URLs', value: 'ExcludeLiteralURLs' },
            { label: 'Exclude Regular Expression', value: 'ExcludeRegexFilter' },
            { label: 'Include Regular Expression', value: 'IncludeRegexFilter' },
            { label: 'Missing Category', value: 'MissingCategoryFilter' }
        ]" v-model:value="localFilter.type" />
      </div>
      <div class="col-8 configItem">
        <label>Filter Settings</label>
        <LiteralURLList v-if="localFilter.type === 'ExcludeLiteralURLs'" :filter-parameters="localFilter.filterParameters" @parametersUpdated="parametersUpdated"/>
        <RegexFilter v-if="localFilter.type === 'ExcludeRegexFilter' || localFilter.type === 'IncludeRegexFilter'" :filter-parameters="localFilter.filterParameters" @parametersUpdated="parametersUpdated"/>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { NSelect } from "naive-ui";
import LiteralURLList from "@/components/filtertypes/LiteralURLList";
import RegexFilter from "@/components/filtertypes/RegexFilter";

export default {
  name: "FilterConfig",
  data() {
    return {
      "localFilter":  _.clone(this.filter)
    }
  },
  watch: {
    localFilter: {
      handler() {
        this.$emit('filterChanged', this.localFilter)
      },
      deep: true
    }
  },
  methods: {
    parametersUpdated(newParams) {
      this.localFilter.filterParameters = newParams;
    }
  },
  components: {RegexFilter, LiteralURLList, NSelect },
  props: ["filter"]
}
</script>

<style scoped>

</style>