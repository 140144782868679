<template>
  <div class="row">
    <div class="col-5">
      <label>Categoriser Label</label>
      <n-input v-model:value="localCustomCategoriser.categoriserLabel" />
    </div>
    <div class="col-5">
      <label>Category Name</label>
      <n-input v-model:value="localCustomCategoriser.categoryName"/>
    </div>
    <div class="col-2">
      Close
    </div>
  </div>
  <div class="row" v-for="(category, key) in localCustomCategoriser.categories" v-bind:key="key">
    <div class="col-12">
      <n-input pair separator=":" :default-value="[key, category]" @focus="gotFocusCategory(key)" @change="categoryEdited"/>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <n-button @click="addCategory">Add Category</n-button>
    </div>
  </div>
</template>

<script>
import { NInput, NButton } from "naive-ui";
import _ from "lodash";

export default {
  name: "CustomCategoriser",
  data() {
    return {
      localCustomCategoriser: _.cloneDeep(this.customCategoriser),
      getFocusKey: null
    }
  },
  methods: {
    addCategory() {
      this.localCustomCategoriser.categories[""] = "";
    },
    gotFocusCategory(key) {
      // There's a whole lot of jiggery pokery that goes into editing pairs (or maybe there's not and my brain just isn't working?)
      this.focusKey = key;
    },
    categoryEdited(keyVal) {
      const key = keyVal[0];
      const val = keyVal[1];

      // If the key has changed then delete the old key and replace it with a new one.
      if (key != this.focusKey) {
        delete this.localCustomCategoriser.categories[this.focusKey];
      }

      this.localCustomCategoriser.categories[key] = val;
    }
  },
  components: { NInput, NButton },
  props: [ "customCategoriser" ],
  emits: [ "categoriserUpdated" ],
  watch: {
    localCustomCategoriser: {
      handler() {
        this.$emit('categoriserUpdated', this.localCustomCategoriser)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>