<template>
  <div>
    <div v-for="(options, paramName) in interfaceInfo[this.localCategoriser.categoriserLabel].settableParameters" v-bind:key="paramName">
      <div class="row">
        <div class="col-3">
          {{ paramName }}:
        </div>
        <div class="col-9">
          <!-- If the interface options array is not empty then this will show a dropdown of options -->

          <n-input
                  v-if="availableValuesForParam(paramName).length === 0"
                  v-model:value="localCategoriser.parameters[paramName]"
          />
          <n-select
                  v-else
                  :options="valuesAsOptions(paramName)"
                  v-model:value="localCategoriser.parameters[paramName]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { NInput, NSelect } from "naive-ui";
import CategoriserInterfaceInfo from "@/assets/resources/categoriser_interface_info.json"

export default {
  name: "CategoriserSetParameters",
  data() {
    return {
      interfaceInfo: CategoriserInterfaceInfo,
      localCategoriser: _.cloneDeep(this.categoriser)
    }
  },
  watch: {
    localCategoriser: {
      handler() {
        this.$emit('categoriserUpdated', this.localCategoriser)
      },
      deep: true
    }
  },
  methods: {
    availableValuesForParam(param) {
      return this.interfaceInfo[this.localCategoriser.categoriserLabel].settableParameters[param];
    },
    valuesAsOptions(param) {
      return _.map(this.availableValuesForParam(param), p => {
        return { value: p, label: p };
      });
    }
  },
  components: { NInput, NSelect },
  props: [ "categoriser" ]
}
</script>

<style scoped>

</style>