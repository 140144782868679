<template>
  <div class="portletContainer">
    <div class="portletHeader">
      <slot name="header"></slot>
    </div>
    <div :class="isScrollable ? 'portletContent' : 'portletContentNoScroll'">
      <div>
        <slot name="content"></slot>
      </div>
    </div>
    <div class="portletFooter" v-if="hasFooter">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "ConfigPortlet",
  computed: {
    hasFooter() {
      return !!this.$slots.footer;
    },
    isScrollable() {
      if (_.isUndefined(this.canScroll)) return true;

      return !!this.canScroll;
    }
  },
  props: [ "canScroll" ]
}
</script>

<style scoped>
  .portletContainer {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    border: 1px solid black;
    margin: 3px;
  }

  .portletHeader {
    border-bottom: 1px solid black;
    font-size: 12pt;
    padding: 5px;
  }

  .portletContent {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
    height: 0px;
  }

  .portletContentNoScroll {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }
</style>