<template>
  <div class="row">
    <n-select :options="readersAvailable" v-model:value="localWeightSettings.source" class="col-6 configItem"/>
    <n-select :options="fieldsForSelectedReader" v-model:value="localWeightSettings.field" class="col-6 configItem"/>
  </div>
  <div class="row">
      <div class="col-4">Min</div>
      <div class="col-4">Max</div>
      <div class="col-4">Weight</div>
  </div>
  <div class="row" v-for="(range, index) in localWeightSettings.ranges" v-bind:key="index">
      <div class="col-3">
        <n-input v-model:value="range.min" />
      </div>
      <div class="col-3">
          <n-input v-model:value="range.max" />
      </div>
      <div class="col-3">
          <n-input v-model:value="range.weight" />
      </div>
      <div class="col-3">
          <div @click="deleteRange(index)">x</div>
      </div>
  </div>
  <div class="row">
      <n-button @click="addRange">Add Range</n-button>
  </div>
</template>

<script>
import { NSelect, NInput, NButton } from 'naive-ui';
import _ from "lodash";

export default {
  name: "RangeWeight",
  methods: {
    addRange() {
      if (!_.has(this.localWeightSettings, "ranges")) {
        this.localWeightSettings.ranges = [];
      }

      this.localWeightSettings.ranges.push({
        min: 0,
        max: 0,
        weight: 0
      });
    },
    deleteRange(index) {
      this.localWeightSettings.ranges.splice(index, 1);
    }
  },
  data() {
    return {
      localWeightSettings: _.cloneDeep(this.weightSettings)
    }
  },
  components: { NSelect, NInput, NButton },
  // FIXME: Copied from ScorerField. Is there a reasonable way to do this with mixins?
  computed: {
    readersAvailable() {
      // Convert the keys in the readerFields object to Naive UI options objects.

      const availableReaders = [];

      for (const key in this.readerFields) {
        availableReaders.push({
          label: key,
          value: key
        })
      }

      return availableReaders;
    },
    fieldsForSelectedReader() {
      // Get the possible values for the selected reader and convert them to Naive UI options objects.

      if (_.isUndefined(this.localWeightSettings.source)) {
        return [];
      }

      return _.map(this.readerFields[this.localWeightSettings.source], function(field) {
        return { label: field, value: field }
      });
    }
  },
  props: [ "weightSettings", "readerFields" ],
  watch: {
    localWeightSettings: {
      handler() {
        this.$emit("rangeWeightsChanged", this.localWeightSettings);
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>