<template>
  <div>
    <n-input placeholder="JDBC URL" v-model:value="localParams.jdbcUrl"/>
    <n-input placeholder="User" v-model:value="localParams.user"/>
    <n-input placeholder="Password" v-model:value="localParams.password"/>
    <n-input placeholder="Date Pattern" v-model:value="localParams.datePattern"/>
    <v-ace-editor
        v-model:value="localParams.sql"
        lang="sql"
        theme="chrome"
        min-lines="5"
        max-lines="100"
    />
    Fields:
    <div v-for="(fieldDef, index) in localParams.fieldDefs" v-bind:key="index">
      <n-input placeholder="Field Name" v-model:value="fieldDef.fieldTitle"/>
      <n-select :options="aggregations" v-model:value="fieldDef.aggregationMethod"/>
    </div>
    <n-button @click="addField">
      Add Field
    </n-button>
  </div>
</template>

<script>
import {NSelect, NInput, NButton} from "naive-ui";
import _ from "lodash";
import {VAceEditor} from "vue3-ace-editor";

export default {
  name: "SQLReader",
  data: function() {
    return {
      aggregations: [
        { label: "AVG", value: "AVG" },
        { label: "MAX", value: "MAX" },
        { label: "MIN", value: "MIN" },
        { label: "SUM", value: "SUM" }
      ],
      localParams: _.cloneDeep(this.sourceParams)
    }
  },
  components: { NSelect, NInput, NButton, VAceEditor },
  props: [ "sourceParams" ],
  watch: {
    localParams: {
      handler() {
        this.$emit("paramsChanged", this.localParams);
      },
      deep: true
    }
  },
  methods: {
    addField() {
      if (_.isUndefined(this.localParams.fieldDefs)) {
        this.localParams.fieldDefs = [];
      }

      this.localParams.fieldDefs.push({});
    }
  }
}
</script>

<style scoped>

</style>