<template>
  <div>
    <div class="row">
      <div class="col-12">
        <n-select :options="availableCategorisers" multiple filterable @update:value="categorisersChanged" :value="displayCategorisers" :render-tag="renderCategoriserTag()"/>
      </div>
    </div>
    <CustomCategoriser v-if="openCategoriserIndex != -1 && localCategoriserConfig[openCategoriserIndex].categoriserType === 'Inline'"
                       :custom-categoriser="localCategoriserConfig[openCategoriserIndex]"
                       @categoriserUpdated="categoriserUpdated" :key="openCategoriserIndex"/>  <!-- The open categoriser is inline -->
    <CategoriserSetParameters v-else-if="openCategoriserIndex != -1"
                              :categoriser="localCategoriserConfig[openCategoriserIndex]"
                              @categoriserUpdated="categoriserUpdated" :key="openCategoriserIndex"/> <!-- The open categoriser is not inline -->
    <n-button @click="addCustomCategoriser">Add Custom Categoriser</n-button>
  </div>
</template>

<script>
import { NButton, NSelect, NTag } from "naive-ui";
import CustomCategoriser from "@/components/categoriseritems/CustomCategoriser";
import Categorisers from "@/assets/resources/categorisers.json";
import _ from "lodash";
import {h} from "vue";
import CategoriserSetParameters from "@/components/categoriseritems/CategoriserSetParameters";

export default {
  name: "CategoriserConfig",
  data() {
    return {
      openCategoriserIndex: -1, // The index of the custom categoriser currently being worked on (-1 indicates no custom categoriser work is in progress)
      localCategoriserConfig: _.cloneDeep(this.categoriserConfig)
    }
  },
  methods: {
    addCustomCategoriser() {
      const newCustom = {
        categoriserType: "Inline",
        categoriserLabel: "New Custom Categoriser",
        categoryName: "",
        categories: {}
      };

      this.localCategoriserConfig.push(newCustom);

      this.openCategoriserIndex = this.localCategoriserConfig.length - 1;
    },
    categorisersChanged(vals) {
      let oldLength = this.localCategoriserConfig.length;

      // Remove any categorisers which aren't in the updated array for the select element.
      this.localCategoriserConfig = _.filter(this.localCategoriserConfig, categoriser => {
        return vals.includes(categoriser.categoriserLabel);
      });

      // If a categoriser has been removed then set the open categoriser index to -1 - A temporary workaround until we have time to do proper shuffling
      if (oldLength > this.localCategoriserConfig.length) {
        this.openCategoriserIndex = -1;
      }

      // Add any categorisers which aren't currently in the updated array - presumably one or more have been selected.
      const currentKnown = _.map(this.localCategoriserConfig, categoriser => {
        return categoriser.categoriserLabel; // Start by building a list of currently set categorisers.
      });

      // Get the new vals without the already known readers.
      const toAdd = (_.filter(vals, val => {
        return !currentKnown.includes(val);
      }));

      // Add all the new categorisers.
      _.forEach(toAdd, addCat => {
        this.localCategoriserConfig.push(Categorisers[addCat]);
      });
    },
    categoriserUpdated(categoriser) {
      this.localCategoriserConfig[this.openCategoriserIndex] = categoriser;
    },
    // Returns true if a categoriser with the given label has already been selected.
    isCategoriserSelected(label) {
      const categoriser = this.findCategoriserByLabel(label);

      return !!categoriser;
    },
    findCategoriserByLabel(label) {
      return _.find(this.localCategoriserConfig, categoriser => {
        return categoriser.categoriserLabel === label;
      });
    },
    findIndexByLabel(label) {
      return _.findIndex(this.localCategoriserConfig, categoriser => {
        return categoriser.categoriserLabel === label;
      });
    },
    // Returns true if the categoriser with the given label is an inline categoriser.
    categoriserIsCustom(label) {
      const categoriser = this.findCategoriserByLabel(label);

      if (!categoriser) return false;

      return categoriser.categoriserType === 'Inline';
    },
    renderCategoriserTag() {
      // TODO: JavaScript is rusty but there MUST be a better way to access the Vue component from within h() than to use
      //   a closure like this.

      const me = this;

      return ({option, handleClose}) => {
        return h(
            NTag,
            {
              closable: true,
              type: me.categoriserIsCustom(option.value) ? 'info' : 'primary',
              onClick(e) {
                //if (me.categoriserIsCustom(option.value)) {
                me.openCategoriserIndex = me.findIndexByLabel(option.value);
                //}

                e.stopPropagation();
                e.preventDefault();
              },
              onMouseDown(e) {
                e.preventDefault();
              },
              onClose(e) {
                e.stopPropagation();
                handleClose();
              }
            },
            {default: () => option.label}
        )
      }
    }
  },
  computed: {
    availableCategorisers() {
      return _.map(Categorisers, (categoriser, key) => {
        return {
          label: categoriser.categoriserLabel,
          value: key,
          disabled: this.isCategoriserSelected(categoriser.categoriserLabel) // Disable this selection if it's already been selected.
        }
      });
    },
    displayCategorisers() {
      return _.map(this.localCategoriserConfig, function(cat) {
        return cat.categoriserLabel;
      })
    }
  },
  props: [ "categoriserConfig" ],
  components: {CategoriserSetParameters, CustomCategoriser, NSelect, NButton },
  watch: {
    localCategoriserConfig: {
      handler() {
        this.$emit('categorisersChanged', this.localCategoriserConfig)
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>