<template>
  <div class="row">
    <div class="col-8">
      <n-input placeholder="Regular Expression" v-model:value="localParameters.regex"/>
    </div>
    <div class="col-4">
      <n-input placeholder="Test URL" v-model:value="testUrl" :status="regexStatus">
        <template #suffix v-if="testRegex">
          <n-icon v-if="regexMatches" :component="SuccessIcon" color="#009900" size="large"/>
          <n-icon v-else :component="FailIcon" color="#990000" size="large"/>
        </template>
      </n-input>
    </div>
  </div>
</template>

<script>
import {NInput, NIcon} from "naive-ui";
import {CheckmarkSharp, CloseSharp} from "@vicons/ionicons5";
import _ from "lodash";

export default {
  name: "RegexFilter",
  data() {
    return {
      localParameters: _.cloneDeep(this.filterParameters),
      testUrl: "",
      SuccessIcon: CheckmarkSharp,
      FailIcon: CloseSharp
    }
  },
  watch: {
    localParameters: {
      handler() {
        this.$emit("parametersUpdated", this.localParameters)
      },
      deep: true
    },
  },
  computed: {
    testRegex() {
      if (this.testUrl.trim().length === 0 || this.localParameters.regex.trim().length === 0) return false;

      return true;
    },
    regexMatches() {
      if (this.testUrl.match(this.localParameters.regex)) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {NInput, NIcon },
  props: [ "filterParameters" ]
}
</script>

<style scoped>

</style>