import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";
import { createAuth0, authGuard } from "@auth0/auth0-vue";
import GlobalContainer from "@/components/GlobalContainer.vue";

import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-chrome';
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import './assets/main.css';

const routes = [
 { path: '/job/:id', component: GlobalContainer, beforeEnter: authGuard }
]

const router = createRouter({ routes, history: createWebHistory() });

createApp(App)
    .use(router)
    .use(createAuth0({
        domain: process.env.VUE_APP_AUTH_DOMAIN,
        clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
        authorizationParams: {
            redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
            audience: process.env.VUE_APP_AUTH_AUDIENCE
        }
    }))
    .mount('#app')
