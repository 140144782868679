<template>
  <div>
    <div class="row">
      <div class="col-3 configItem">
        <label>File Location</label>
        <n-input v-model:value="localParams.fileLocation"/>
      </div>
      <div class="col-3 configItem">
        <label>URL Field Title</label>
        <n-input v-model:value="localParams.urlFieldTitle"/>
      </div>
      <div class="col-3 configItem">
        <label>Date Field Title</label>
        <n-input v-model:value="localParams.dateFieldTitle"/>
      </div>
      <div class="col-3 configItem">
        <label>Date Pattern</label>
        <n-input v-model:value="localParams.datePattern"/>
      </div>
    </div>
    <n-divider />
    <div v-for="(fieldDef, index) in localParams.fieldDefs" v-bind:key="index" class="row">
      <div class="col-10 configItem">
        <label>Field Name</label>
        <n-input v-model:value="fieldDef.fieldTitle"/>
      </div>
      <div class="col-2">
        <label>Aggregation Method</label>
        <n-select :options="aggregations" v-model:value="fieldDef.aggregationMethod"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <n-button @click="addField">
          Add Field
        </n-button>
      </div>
    </div>
  </div>
</template>

<script>
import {NSelect, NInput, NButton, NDivider} from "naive-ui";
import _ from "lodash";

export default {
  name: "CSVReader",
  data: function() {
    return {
      aggregations: [
        { label: "AVG", value: "AVG" },
        { label: "MAX", value: "MAX" },
        { label: "MIN", value: "MIN" },
        { label: "SUM", value: "SUM" }
      ],
      localParams: _.cloneDeep(this.sourceParams)
    }
  },
  components: { NSelect, NInput, NButton, NDivider },
  props: [ "sourceParams" ],
  watch: {
    localParams: {
      handler() {
        this.$emit("paramsChanged", this.localParams);
      },
      deep: true
    }
  },
  methods: {
    addField() {
      if (_.isUndefined(this.localParams.fieldDefs)) {
        this.localParams.fieldDefs = [];
      }

      this.localParams.fieldDefs.push({});
    }
  }
}
</script>

<style scoped>

</style>