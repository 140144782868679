<template>
  <div>
    <div class="row">
      <div class="col-10 configItem">
        <label>Reader Name</label>
        <n-input placeholder="Reader Label" v-model:value="localReader.sourceLabel"/>
      </div>
      <div class="col-2 configItem">
        <label>Reader Type</label>
        <n-select :options="readerTypes" placeholder="Reader Type" @change="changeType" v-model:value="localReader.sourceType" />
      </div>
    </div>
    <n-divider />
    <CSVReader v-if="localReader.sourceType === 'CSV'" :source-params="localReader.sourceParams" @paramsChanged="paramsChanged" />
    <GSCReader v-else-if="localReader.sourceType === 'GSC' || localReader.sourceType === 'WARGSC'" :source-params="localReader.sourceParams" @paramsChanged="paramsChanged" />
    <GAReader v-else-if="localReader.sourceType === 'GA'" :source-params="localReader.sourceParams" :available-categorisers="availableCategorisers" @paramsChanged="paramsChanged" />
    <SQLReader v-else-if="localReader.sourceType === 'SQL'" :source-params="localReader.sourceParams" @paramChanged="paramsChanged" />
  </div>
</template>

<script>
import { NSelect, NInput, NDivider } from "naive-ui";
import _ from "lodash";
import CSVReader from "@/components/readertypes/CSVReader";
import GSCReader from "@/components/readertypes/GSCReader";
import SQLReader from "@/components/readertypes/SQLReader";
import GAReader from "@/components/readertypes/GAReader";

export default {
  name: "ReaderConfig",
  data: function() {
    return {
      "readerTypes": [
        { label: "CSV", value: "CSV" },
        { label: "Google Analytics", value: "GA" },
        { label: "Google Search Console", value: "GSC" },
        { label: "Google Search Console (Weighted Average Rank)", value: "WARGSC" },
        { label: "SQL", value: "SQL" }
      ],
      "localReader": _.cloneDeep(this.reader)
    }
  },
  methods: {
    changeType() {
      this.localReader.sourceParams = {};
    },
    paramsChanged(newParams) {
      this.localReader.sourceParams = newParams;
    }
  },
  watch: {
    localReader: {
      handler() {
        this.$emit("readerChanged", this.localReader);
      },
      deep: true
    }
  },
  components: { SQLReader, GSCReader, CSVReader, GAReader, NSelect, NInput, NDivider },
  props: ["reader", "availableCategorisers" ]
}
</script>

<style scoped>

</style>