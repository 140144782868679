<template>
  <div class="row">
    <div class="col-6">
      <n-input placeholder="View ID" v-model:value="localParams.viewId"/>
    </div>
    <div class="col-6">
      <n-select placeholder="Translation Categoriser" :options="translationCategoriserOptions" v-model:value="localParams.lpToUrlCategoriser"></n-select>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      Field Title
    </div>
    <div class="col-6">
      Aggregation
    </div>
  </div>
  <div class="row" v-for="(fieldDef, index) in localParams.fieldDefs" v-bind:key="index">
    <div class="col-6">
      <n-input v-model:value="fieldDef.fieldTitle" />
    </div>
    <div class="col-6">
      <n-select v-model:value="fieldDef.aggregationMethod" :options="aggregations"/>
    </div>
  </div>
  <div class="row">
    <n-button @click="addField">Add Field</n-button>
  </div>
  <div class="row">
    <div class="col-4">
      Dimension
    </div>
    <div class="col-4">
      Operator
    </div>
    <div class="col-4">
      Value
    </div>
  </div>
  <div class="row" v-for="(filterDef, index) in localParams.filters" v-bind:key="index">
    <div class="col-4">
      <n-input v-model:value="filterDef.dimension" />
    </div>
    <div class="col-4">
      <n-input v-model:value="filterDef.operator" />
    </div>
    <div class="col-4">
      <n-input v-model:value="filterDef.value" />
    </div>
  </div>
  <div class="row">
    <n-button @click="addFilter">Add Filter</n-button>
  </div>
</template>

<script>
import _ from "lodash";
import { NInput, NSelect, NButton } from "naive-ui";

export default {
  name: "GAReader",
  data: function() {
    return {
      localParams: _.cloneDeep(this.sourceParams),
      aggregations: [
        { label: "AVG", value: "AVG" },
        { label: "MAX", value: "MAX" },
        { label: "MIN", value: "MIN" },
        { label: "SUM", value: "SUM" }
      ]
    }
  },
  props: [ "sourceParams", "availableCategorisers" ],
  components: { NInput, NSelect, NButton },
  watch: {
    localParams: {
      handler() {
        this.$emit("paramsChanged", this.localParams);
      },
      deep: true
    }
  },
  methods: {
    availableTranslationCategorisers() {
      return _.filter(this.availableCategorisers, cat => cat.translatesToURL);
    },
    addFilter() {
      if (_.isUndefined(this.localParams.filters)) {
        this.localParams.filters = [];
      }

      this.localParams.filters.push({});
    },
    addField() {
      if (_.isUndefined(this.localParams.fieldDefs)) {
        this.localParams.fieldDefs = [];
      }

      this.localParams.fieldDefs.push({});
    }
  },
  computed: {
    translationCategoriserOptions() {
      return _.map(this.availableTranslationCategorisers(), cat => {
        return {
          label: cat.categoriserLabel,
          value: cat.categoriserLabel
        }
      });
    }
  }
}
</script>

<style scoped>

</style>