<template>
  <n-tabs>
    <n-tab-pane name="easyConfig" tab="Easy Config">
      <easy-config :config-json="configJson" @configUpdated="configUpdated" :key="key" :job-id="jobId" />
    </n-tab-pane>
    <n-tab-pane name="jsonConfig" tab="JSON Config">
      <JSONConfig :config-json="configJson" @configUpdated="configUpdated" :key="key"/>
    </n-tab-pane>
  </n-tabs>
  <div style="position: absolute; top: 0; right: 0">
    <n-button type="primary" @click="save">Save</n-button>
    <n-button type="default" @click="cancel">Cancel</n-button>
  </div>
</template>

<script>
import {NTabPane, NTabs, NButton} from "naive-ui";
import axios from 'axios';
import EasyConfig from "@/components/EasyConfig";
import JSONConfig from "@/components/JSONConfig";
import moment from "moment";


export default {
  name: "GlobalContainer",
  data() {
    return {
      configJson: {
        startDate: moment(new Date()).format("YYYY-MM-DD"),
        granularity: "ALL",
        autoCanonicalise: true,
        sources: [],
        filters: [],
        categorisers: [],
        scorer: {
          seriesCombiner: {
            type: "SumAll"
          },
          pointCombiner: "Additive"
        }
      },
      key: 0,
      jobId: this.$route.params.id
    }
  },
  mounted() {
    // Load existing config from API if previously saved
    this.$auth0.getAccessTokenSilently().then((token) => {
      axios.get(process.env.VUE_APP_JOB_API_URL + '/job/config/' + this.$route.params.id, { headers: { Authorization: 'Bearer ' + token } })
          .then((response) => {
            if (response.data.config !== '') {
              this.configUpdated(JSON.parse(response.data.config));
              this.key++; // Forces rendering of the component after data is set
            }
          }).catch(() => {}); // TODO: Handle API errors
    });
  },
  methods: {
    configUpdated(newConfig) {
      this.configJson = newConfig;
    },
    save() {
      // Save the config against the jobId, providing a file name and the config JSON
      this.$auth0.getAccessTokenSilently().then((token) => {
        axios.post(process.env.VUE_APP_JOB_API_URL + '/job/config',
            { jobId: this.$route.params.id, fileName: 'settings.json', configJson: JSON.stringify(this.configJson) },
            { headers: { Authorization: 'Bearer ' + token } })
            .then(() => location.href = process.env.VUE_APP_JOB_UI_URL + '/config/' + this.$route.params.id)
            .catch(() => {});
      })
    },
    cancel() {
      location.href = process.env.VUE_APP_JOB_UI_URL + '/config/' + this.$route.params.id;
    }
  },
  components: {JSONConfig, EasyConfig, NTabPane, NTabs, NButton}
}
</script>

<style scoped>
.n-button {
  margin: 5px;
}
</style>