<template>
  <div>
    {{ localCategoryWeightSettings }}
    <div class="row">
      <div class="col-6"><n-select :options="categoriserOptions" v-model:value="localCategoryWeightSettings.source" /></div>
      <div class="col-6"><n-select :options="availableCategories" v-model:value="localCategoryWeightSettings.category" /></div>
    </div>
    <div class="row" v-for="(weight, category) in localCategoryWeightSettings.weights" v-bind:key="category">
      <div class="col-12">
        <n-input pair separator=":" :default-value="[category, weight]" @focus="categoryGotFocus(category)" @change="categoryChanged"/>
      </div>
    </div>
    <n-button @click="addCategoryWeight">Add Category</n-button>
  </div>
</template>

<script>
import _ from "lodash";
import { NSelect, NInput, NButton } from "naive-ui";
import CategoriserInterfaceInfo from "@/assets/resources/categoriser_interface_info.json";

export default {
  name: "CategoryWeight",
  data() {
    return {
      localCategoryWeightSettings: _.clone(this.categoryWeightSettings),
      focusedCategory : null
    }
  },
  components: { NSelect, NInput, NButton },
  watch: {
    localCategoryWeightSettings: {
      handler() {
        this.$emit("catWeightsChanged", this.localCategoryWeightSettings);
      },
      deep: true
    }
  },
  methods: {
    addCategoryWeight() {
      if (!_.has(this.localCategoryWeightSettings, "weights")) {
        this.localCategoryWeightSettings.weights = {};
      }

      this.localCategoryWeightSettings.weights[""] = 0;
    },
    categoryGotFocus(category) {
      // As with CustomCategoriser, there's a lot of work to manually track and update the values when working with pairwise elements.

      this.focusedCategory = category;
    },
    categoryChanged(keyVal) {
      console.log(this.focusedCategory);

      const key = keyVal[0];
      const val = keyVal[1];

      // If the key has changed then delete the old key and replace it with a new one.
      if (key != this.focusedCategory) {
        delete this.localCategoryWeightSettings.weights[this.focusedCategory];
      }

      this.localCategoryWeightSettings.weights[key] = _.isNaN(val) ? 0 : parseFloat(val); // Weights should be numeric.
    }
  },
  computed: {
    categoriserOptions() {
      return _.map(this.availableCategorisers, (cat) => {
        return {
          label: cat.categoriserLabel,
          value: cat.categoriserLabel
        }
      });
    },
    availableCategories() {
      if (!this.localCategoryWeightSettings.source) return [];

      const selectedCategoriserObj = _.find(this.availableCategorisers, ["categoriserLabel", this.localCategoryWeightSettings.source]);

      if (!selectedCategoriserObj) return [];
      // Inline categorisers have their categories defined within their object. For pre-built categorisers
      if (selectedCategoriserObj.categoriserType === "Inline") {
        return _.map(_.keys(selectedCategoriserObj.categories), cat => {
          return {
            label: cat,
            value: cat
          }
        });
      } else {
        return _.map(CategoriserInterfaceInfo[selectedCategoriserObj.categoriserLabel].categories, (cat) => {
          return {
            label: cat,
            value: cat
          }
        });
      }
    }
  },
  props: [ "categoryWeightSettings", "availableCategorisers" ]
}
</script>

<style scoped>

</style>