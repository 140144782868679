<template>
  <div>
    <div class="row">
      <n-select :options="readersAvailable" v-model:value="localWeightSettings.source" class="col-4 configItem"/>
      <n-select :options="fieldsForSelectedReader" v-model:value="localWeightSettings.field" class="col-4 configItem"/>
      <n-input-number v-model:value="localWeightSettings.weight" class="col-4 configItem"/>
    </div>
  </div>
</template>

<script>
import { NSelect, NInputNumber } from 'naive-ui';
import _ from 'lodash';

export default {
  name: "ScorerField",
  data() {
    return {
      localWeightSettings: _.cloneDeep(this.weightSettings)
    }
  },
  props: [ "weightSettings", "readerFields" ],
  watch: {
    localWeightSettings: {
      handler() {
        this.$emit("weightsChanged", this.localWeightSettings);
      },
      deep: true
    }
  },
  computed: {
    readersAvailable() {
      // Convert the keys in the readerFields object to Naive UI options objects.

      const availableReaders = [];

      for (const key in this.readerFields) {
        availableReaders.push({
          label: key,
          value: key
        })
      }

      return availableReaders;
    },
    fieldsForSelectedReader() {
      // Get the possible values for the selected reader and convert them to Naive UI options objects.

      if (_.isUndefined(this.localWeightSettings.source)) {
        return [];
      }

      return _.map(this.readerFields[this.localWeightSettings.source], function(field) {
        return { label: field, value: field }
      });
    }
  },
  components: { NSelect, NInputNumber }
}
</script>

<style scoped>

</style>