<template>
  <div class="row">
    <div class="col-12">
      <n-input type="textarea" placeholder="URLs" v-model:value="commaSeparatedUrls"/>
    </div>
  </div>
</template>

<script>
import {NInput} from "naive-ui";
import _ from "lodash";

export default {
  name: "LiteralURLList",
  data() {
    return {
      localParameters: _.cloneDeep(this.filterParameters),
      // Wrangle an array of URLs into a newline separated string
      commaSeparatedUrls: _.isUndefined(this.filterParameters.excludeList) ? "" : _.join(this.filterParameters.excludeList, "\n")
    }
  },
  watch: {
    urlArray() {
      this.$emit("parametersUpdated", this.newParameters)
    }
  },
  computed: {
    newParameters() {
      return {
        excludeList: this.urlArray
      }
    },
    urlArray() {
      // Wrangle a newline separated string into an array of URLs.
      return _.map(this.commaSeparatedUrls.split("\n"), url => url.trim());
    }
  },
  components: {NInput},
  props: [ "filterParameters" ]
}
</script>

<style scoped>

</style>