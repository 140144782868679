<template>
  <div>
    <div class="row">
      <div class="col-12">
        <n-input placeholder="Site Name" v-model:value="localParams.siteName"/>
      </div>
    </div>
    Filters:
    <div class="row">
      <div class="col-4">Field</div>
      <div class="col-4">Operator</div>
      <div class="col-4">Value</div>
    </div>
    <div v-for="(filter, index) in localParams.filters" v-bind:key="index" class="row">
      <div class="col-4">
        <n-select :options="[
            { label: 'Country', value: 'COUNTRY' },
            { label: 'Device', value: 'DEVICE' },
            { label: 'Page', value: 'PAGE' },
            { label: 'Query', value: 'QUERY' }
        ]" v-model:value="filter.dimension" />
      </div>
      <div class="col-4">
        <n-select :options="[
            { label: 'Contains', value: 'CONTAINS' },
            { label: 'Equals', value: 'EQUALS' },
            { label: 'Not Contains', value: 'NOT_CONTAINS' },
            { label: 'Not Equals', value: 'NOT_EQUALS' },
            { label: 'Including Regex', value: 'INCLUDING_REGEX' },
            { label: 'Excluding Regex', value: 'EXCLUDING_REGEX' }
        ]" v-model:value="filter.operator"/>
      </div>
      <div class="col-4">
        <n-select v-if="filter.dimension === 'COUNTRY'" :options="countryOptions" v-model:value="filter.expression" filterable="true"/>
        <n-select v-else-if="filter.dimension === 'DEVICE'" :options="[
            { label: 'Desktop', value: 'DESKTOP' },
            { label: 'Mobile', value: 'MOBILE' },
            { label: 'Tablet', value: 'TABLET' }
        ]" v-model:value="filter.expression" />
        <n-input v-else v-model:value="filter.expression"></n-input>
      </div>
    </div>
    <n-button @click="addFilter">
      Add Filter
    </n-button>
  </div>
</template>

<script>
import {NSelect, NInput, NButton } from "naive-ui";
import _ from "lodash";
import countries from "@/assets/resources/countries.json";

export default {
  name: "CSVReader",
  data: function() {
    return {
      localParams: _.cloneDeep(this.sourceParams)
    }
  },
  components: { NSelect, NInput, NButton },
  props: [ "sourceParams" ],
  watch: {
    localParams: {
      handler() {
        this.$emit("paramsChanged", this.localParams);
      },
      deep: true
    }
  },
  computed: {
    countryOptions() {
      return _.map(countries, (val, key) => {
        return {
          label: val,
          value: key
        }
      })
    }
  },
  methods: {
    addFilter() {
      if (_.isUndefined(this.localParams.filters)) {
        this.localParams.filters = [];
      }

      this.localParams.filters.push({});
    }
  }
}
</script>

<style scoped>

</style>