<template>
<!--  <GlobalContainer></GlobalContainer>-->
  <router-view/>
</template>

<script>
// import GlobalContainer from "@/components/GlobalContainer";

export default {
  name: 'App',
  components: {
    // GlobalContainer
  }
}
</script>

<style>
#app {
  font-family: 'Roboto', sans-serif;
  color: #2c3e50;
}
</style>
